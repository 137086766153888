@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.headers {
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 100;
  padding-left: 10%;
}
.content {
  background-image: url("../../assets/bg01_img_n.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  width: 100%;
  // background: RGBA(236, 234, 250, 1);
  position: relative;
  .login-card {
    right: 10%;
    top: 20%;
    position: absolute;
    width: 400px;
    height: 300px;
  }
  .login-btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
