.row2 {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 58px);
    .row2-card {
      padding: 10px 0;
      height: 100%;
      overflow-y: scroll;
      box-sizing: border-box;
      .table-header {
        box-sizing: border-box;
        padding-left: 50px;
        color: #999999;
        font-size: 14px;
        text-align: center;
      }
      .item {
        color: rgba(51, 51, 51, 1);
        height: 24px;
        line-height: 24px;
        font-size: 14px;
        border-radius: 4px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .item-index {
          margin-left: 10px;
          min-width: 18px;
          height: 18px;
          border-radius: 4px;
          border: 2px solid rgba(197, 192, 224, 1);
          color: rgba(197, 192, 224, 1);
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        }
        .item-title {
          margin-left: 10px;
          width: 25%;
        }
        .item-message {
          margin-left: 10px;
          width: 30%;
        }
        .item-other {
          width: 45%;
          display: flex;
          justify-content: space-around;
        }
        .item-check-true {
          height: 20px;
          width: 80px;
          line-height: 20px;
          text-align: center;
          border-radius: 4px;
          font-size: 14px;
          background: #ffffff;
          border: 1px solid #c5c0e0;
        }
        .item-check-false {
          width: 64px;
          height: 24px;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
        }
        .item-date {
          color: rgba(153, 153, 153, 1);
          margin-left: 10px;
          width: 85px;
          text-align: right;
        }
        .item-sale {
          height: 24px;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #7f76bd;
          line-height: 24px;
          text-align: right;
        }
        .item-state {
          width: 54px;
          display: flex;
          justify-content: flex-end;
          .state {
            font-size: 14px;
            width: 54px;
            height: 20px;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 20px;
          }
        }
      }
    }
  }
  .row3 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }