npm .pointer {
    cursor: pointer;
}

// 自定义单选按钮样式
.my-radio {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid #7f76bd;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .my-radio-choice {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: #7f76bd;
    }
}
// 页面class
.contatain {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    .content-wrap {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
        background: #fff;
    }
    .tab {
        display: flex;
        margin-bottom: 8px;
        &-item {
            width: 96px;
            height: 24px;
            border-radius: 4px;
            font-size: 18px;
            text-align: center;
            font-weight: 400;
            line-height: 24px;
            color: #000000;
            cursor: pointer;
            margin-right: 35px;
        }
        &-item-active {
            color: #fff;
            background: #7f76bd;
        }
    }
}

.pr-1 {
    padding-right: 10px;
}
.m-t-10 {
    margin-top: 10px;
}
.m-t-20 {
    margin-top: 20px;
}
.m-t-30 {
    margin-top: 30px;
}
.m-l-5 {
    margin-left: 5px;
}
.m-l-10 {
    margin-left: 10px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-l-30 {
    margin-left: 30px;
}

.m-l-40 {
    margin-left: 40px;
}

.m-r-10 {
    margin-right: 10px;
}
.mt-3 {
    margin-top: 3px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-10 {
    margin-top: 10px;
}
.m-r-5 {
    margin-right: 5px;
}
.m-r-20 {
    margin-right: 20px;
}
.mr-20 {
    margin-right: 20px;
}

.m-r-30 {
    margin-right: 30px;
}

.m-r-40 {
    margin-right: 40px;
}

.m-r-50 {
    margin-right: 50px;
}

.m-r-70 {
    margin-right: 70px;
}

.m-r-80 {
    margin-right: 80px;
}

.m-b-5 {
    margin-bottom: 5px;
}
.m-b-8 {
    margin-bottom: 8px;
}

.m-b-20 {
    margin-bottom: 20px;
}
.m-b-40 {
    margin-bottom: 40px;
}

.m-b-10 {
    margin-bottom: 10px;
}
.m-b-30 {
    margin-bottom: 30px;
}
.m-b-5 {
    margin-bottom: 5px;
}

.remark-text {
    color: #999999;
    font-size: 12px;
}
.font-s14 {
    font-size: 14px;
}
.dialog {
    max-height: 700px;
    overflow-y: scroll;
}

.link {
    color: rgb(127, 117, 191);
    cursor: pointer;
}

.h-100 {
    height: 100%;
}
.m-r-20 {
    margin-right: 20px;
}

.m-lr-10 {
    margin: 0 10px;
}

.m-lr-20 {
    margin: 0 20px;
}

.m-lr-30 {
    margin: 0 30px;
}

.disable {
    cursor: not-allowed;
    color: #c0c4cc;
    pointer-events: none;
}

.suffix {
    display: block;
    margin-top: 5px;
}

.p-r {
    position: relative;
}

.p-rb-0 {
    position: absolute;
    bottom: 0;
    right: 0;
}

.overflow-s {
    overflow: scroll;
}

.overflow-y {
    overflow-y: scroll;
}
.overflow-h {
    overflow: hidden;
}
.width100 {
    width: 100%;
}
.shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 12px 0px;
}
.bg-white {
    background-color: #fff;
}
.bg-purple {
    background-color: #eceafa;
}
.purple {
    color: #7F76BD
}
.text-c {
    text-align: center;
}
.pointer {
    cursor: pointer;
}

.text-center {
    text-align: center !important;
}

.computed-title {
    padding: 3px 8px;
    font-weight: 400;
    font-size: 15px;
    margin-right: 10px;
    width: 75px;
    text-align: center;
    border-left: 4px solid #C5C0E0; ;
  }

.f-14 {
    font-size: 14px;
}

.max-w-800 {
    max-width: 800px;
}

.no-wrap {
    white-space:nowrap;
}

