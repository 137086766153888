@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.row1 {
  display: flex;
  justify-content: center;
  .title {
    background: rgba(236, 234, 250, 1);
    border-radius: 4px;
    width: 100px;
    height: 24px;
    text-align: center;
    color: #333;
    line-height: 24px;
    font-weight: bold;
  }
}
.row2 {
  display: flex;
  flex-wrap: wrap;
  height: 55vh;
  overflow-y: scroll;
  justify-content: space-between;
}
.row3 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
