@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.progress-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 100%;
  background: #c1c1c1;
  border-radius: 4px;
  .playBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 14px;
    border-radius: 4px;
    font-size: 14px;
    color: #7f76bd;
  }
  .playBtn > div {
    width: 2px;
    height: 8px;
    margin: 0 1px;
    background: #ffffff;
    border-radius: 4px;
  }
  .play {
    background: #ffffff;
  }
  .pause {
    background: #7f76bd;
  }
  .slider {
    position: relative;
    margin-left: 3px;
    width: 128px;
    height: 6px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 2px;
    .slider-fill {
      position: relative;
      width: 100%;
      background: #7f76bd;
      height: 4px;
      border-radius: 4px;
    }
    .slider-thumb {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 14px;
      height: 14px;
      background: #c5c0e0;
      border-radius: 50%;
    }
    .slider-thumb::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .now-time {
    font-size: 12px;
    color: #fff;
  }
}
.wh {
  @include wh(100%);
}
.content {
  @include fill();
}
.total-time {
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 20px;
  background: rgba(51, 51, 51, 0.5);
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}
.mark {
  width: 100%;
  height: 148px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.mark:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(51, 51, 51, 0.5);
  height: calc(100% - 18px);
}
.mark:hover::after {
  content: "";
  position: absolute;
  top: 46%;
  left: 52%;
  transform: translate(-50%, -50%);
  background-image: url("../../assets/edit@2x.png");
  background-size: 100% 100%;
  width: 22px;
  height: 24px;
}
.audioitem {
  width: 200px;
  height: 148px;
  border-radius: 4px;
  position: relative;
}
.only-progress {
  @include wh(200px, 28px);
  border-radius: 4px;
  position: relative;
}
.tips {
  border: 1px dashed #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  flex-direction: column;
  cursor: pointer;
}
.tips:hover {
  border-color: #7f76bd;
}
