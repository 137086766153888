.flex-row {
    display: flex;
}

.flex-cloumn {
    display: flex;
    flex-direction: column;
}
.flex-c {
    display: flex;
    align-items: center;
}
.flex-cc {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-ac {
    display: flex;
    align-items: center;
}

.flex-ae {
    align-items: flex-end;
}
.flex-as {
    align-items: flex-start;
}

.space-between {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.space-around {
    justify-content: space-around;
}
.justify-end {
    justify-content: flex-end;
}


.flex-1 {
    flex:1;
}
.flex-wrap {
    flex-wrap: wrap;
}

.flex-as {
    align-items: flex-start;
}
.flex {
    display: flex;
}
.flex-end {
    display: flex;
    justify-content: flex-end;
}
