@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.row1 {
  display: flex;
  justify-content: center;
  .title {
    background: rgba(236, 234, 250, 1);
    border-radius: 4px;
    width: 100px;
    height: 24px;
    text-align: center;
    color: #333;
    line-height: 24px;
    font-weight: bold;
  }
}
.row2 {
  display: flex;
  flex-wrap: wrap;
  height: 55vh;
  overflow-y: scroll;
  justify-content: space-between;
}
.row3 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.scgl-item-image {
  width: 100%;
  height: 148px;
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  position: relative;
  .scgl-left {
    position: absolute;
    top: -5px;
    left: 0;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 54px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
  }
  .item-image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
}
