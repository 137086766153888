@import "~@/style/mixin.scss";@import "~@/style/variables.scss";

.myselect {
  border: 1px solid #7F76BD;
  height: 28px;
  width: 200px;
  border-radius: 4px;
  position: relative;
  .card {
    position: absolute;
    top: 38px;
    width: 100%;
    max-height: 400px;
    overflow-y: scroll;
    min-height: 30px;
    background: #fff;
    z-index: 500;
  }
}
