.home {
    .home-headers {
      height: 60px;
      width: 100%;
      position: fixed;
      z-index: 100;
      display: flex;
      justify-content: center;
      .headers-content {
        width: 1200px;
        display: flex;
        justify-content: space-between;
      }
    }
    .home-content {
      min-height: calc(100vh - 70px);
      width: 100%;
      padding-top: 70px;
      background: rgba(248, 247, 255, 1);
      display: flex;
      justify-content: center;
      .menu-item {
        margin-top: 12px;
        font-size: 16px;
        border-radius: 4px;
        overflow: hidden;
      }
      .content {
        width: 1200px;
        .row1 {
          font-size: 14px;
          background: #fff;
          padding: 4px;
          border-radius: 4px;
          color: rgba(243, 148, 172, 1);
        }
        .row2 {
          margin-top: 10px;
          display: flex;
          height: calc(100vh - 60px - 120px);
          .row2-left {
            width: 20%;
            height: 100%;
            .row2-left-card {
              padding: 15px;
              height: 100%;
              .navigation-item {
                margin-top: 12px;
                padding: 12px;
                color: rgba(153, 153, 153, 1);
                border-radius: 4px;
                display: flex;
                justify-content: center;
                cursor: pointer;
              }
              .navigation-item-choose {
                margin-top: 12px;
                background: rgba(127, 118, 189, 1);
                padding: 12px;
                color: #fff;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                cursor: pointer;
              }
            }
          }
          .row2-right {
            width: 80%;
            min-width: 950px;
            margin-left: 24px;
          }
        }
      }
    }
  }
  .title {
    width: 96px;
    height: 24px;
    background: #eceafa;
    border-radius: 4px;
    line-height: 24px;
    text-align: center;
  }
  
  ::v-deep .el-submenu .el-menu-item {
    min-width: 10px;
  }
  ::v-deep .el-submenu__icon-arrow {
    right: 0px;
  }
  .menu-item > ::v-deep .el-menu-item {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
  }
  ::v-deep .el-submenu__title {
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    background: #fff;
  }
  ::v-deep .is-active {
    background: rgba(127, 118, 189, 1) !important;
    color: #fff !important;
  }
  .menu-item .item-child {
    text-align: center;
    margin-top: 12px;
    font-size: 14px;
    padding-left: 40px;
    border-radius: 4px;
    overflow: hidden;
  }
  .my-icon {
    font-size: 18px;
    line-height: 1;
    margin-right: 10px;
    width: 24;
    height: 18px;
  }